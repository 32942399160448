import React from 'react';

function Portfolio() {
  return (
    <div className="Portfolio">
    </div>
  );
}

export default Portfolio;
