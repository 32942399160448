import React from 'react'

function MastHead () {
    return (
        <header className="masthead">
            <div className="container h-100">
                <div className="row h-100 align-items-center justify-content-center text-center">
                    <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel" data-interval="3500">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div>
                                    <h1 className="text-uppercase text-white font-weight-bold">it's about making ideas happen</h1>
                                    <hr className="divider my-4" />
                                    <p className="text-white-75 font-weight-light mb-5">We do not dream about success, we work for it!</p>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div>
                                    <h1 className="text-uppercase text-white font-weight-bold">Custom Software Engineering</h1>
                                    <hr className="divider my-4" />
                                    <p className="text-white-75 font-weight-light mb-5">We do it with Creativity. Exceptional Quality. Proven Reliability.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default MastHead;