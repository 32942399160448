import React from 'react';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import AboutUs from '../components/AboutUs';
import Masthead from '../components/Masthead';
import Services from '../components/Services';
import Portfolio from '../components/Portfolio';
import OurTeam from '../components/OurTeam';
import Contact from '../components/Contact';

function HomePage() {
  return (
    <div className="App">
      <Navigation />
      <Masthead />
      <AboutUs />
      <Services />
      <Portfolio />
      <OurTeam />
      <Contact />
      <Footer />
    </div>
  );
}

export default HomePage;
