import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Navigation from './components/Navigation';

import Portfolio from './pages/Portfolio';
import HomePage from './pages/HomePage';

import './App.css';

function App () {
    return (
        <div className="App">
            <Navigation />
            <Router>
                <Switch>
                    <Route path="/" exact component={HomePage}/>
                    <Route path="/portfolio" component={Portfolio}/>
                </Switch>
            </Router>
        </div>
    )
}

export default App;