import React from 'react';
import './css/AboutUs.css';

function AboutUs () {
    return (
        <section className="page-section bg-primary about" id="about">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2 className="mt-0">We've got what you need!</h2>
                        <hr className="divider dark my-4" />
                        <p className="mb-4">95FOUR is the digital invigoration your organization needs. Digital transformation is essential for organizations in this day and age. To establish this process for our clients, we master the intertwined nature of business and technology in order to revive existing structures.</p>
                    </div>
                </div>
            </div>
      </section>
    )
}

export default AboutUs;