import React from 'react';
import './css/Employee.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Employee (props) {
    return (
        <div className="employee col-lg-4 col-sm-6">
            <img className="img-fluid" src={props.imgSrc} alt="" />
            <div className="portfolio-box-caption">
                <div className="icon-links text-white-50">
                    {/* <a href="https://www.linkedin.com/in/antonie-zafirov/"><FontAwesomeIcon icon={['fab', 'linkedin-in']} size="2x" className="space-between"/></a> */}
                    <a href={props.linkedinUrl}><FontAwesomeIcon icon={['fab', 'linkedin-in']} size="2x" className="space-between"/></a>
                    {/* <a href="mailto: antonie.zafirov@95four.com"><FontAwesomeIcon icon={['fa', 'envelope']} size="2x"  className="space-between"/></a> */}
                    <a href={props.mailTo}><FontAwesomeIcon icon={['fa', 'envelope']} size="2x"  className="space-between"/></a>
                </div>
                <div className="employee-desc-header">
                  {/* Antonie Zafirov */}
                  {props.employeeName}
                </div>
                <div className="employee-desc-body">
                  {props.employeePosition}
                  {/* Founder & VP Engineering */}
                </div>
            </div>
        </div>
    )
}

export default Employee;