import React from 'react'
import './css/Portfolio.css';
import thumbnail1 from '../assets/img/portfolio/1.png';
import thumbnail2 from '../assets/img/portfolio/2.png';
import thumbnail3 from '../assets/img/portfolio/3.png';
import thumbnail4 from '../assets/img/portfolio/4.jpg';
import thumbnail5 from '../assets/img/portfolio/5.jpg';
import thumbnail6 from '../assets/img/portfolio/6.png';

function Portfolio () {
    return (
      <section className="page-section" id="portfolio">
        <div className="container">
          <h2 className="text-center mt-0">OUR TECHNICAL SOLUTIONS ARE BEING BUILT USING THE LATEST TECHNOLOGY STACK</h2>
          <hr className="divider dark my-4" />
          <div className="row">
            <div className="col-lg-4 col-md-8 text-center">
              <div className="mt-5">
                <div className="wrap">
                  <img className="products img-fluid" src={thumbnail1} alt=""></img>
                  <div className="productsText">Spring Boot</div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-8 text-center">
              <div className="mt-5">
                <div className="wrap">
                  <img className="products img-fluid" src={thumbnail2} alt=""></img>
                  <div className="productsText">React JS</div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-8 text-center">
              <div className="mt-5">
                <div className="wrap">
                  <img className="products img-fluid" src={thumbnail3} alt=""></img>
                  <div className="productsText">React Native</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-8 text-center">
              <div className="mt-5">
                <div className="wrap">
                  <img className="products img-fluid" src={thumbnail4} alt=""></img>
                  <div className="productsText">Android</div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-8 text-center">
              <div className="mt-5">
                <div className="wrap">
                  <img className="products img-fluid" src={thumbnail5} alt=""></img>
                  <div className="productsText">Angular</div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-8 text-center">
              <div className="mt-5">
                <div className="wrap">
                  <img className="products img-fluid" src={thumbnail6} alt=""></img>
                  <div className="productsText">VueJS</div>
                </div>
              </div>
            </div>
          </div>
          </div>
      </section>
    )
}

export default Portfolio;