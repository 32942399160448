import React from 'react'
import Employee from './Employee';
import antonie from '../assets/img/team/antonie_zafirov.jpg';
import filip from '../assets/img/team/filip_trajkovski.jpg';

import './css/OurTeam.css';
function OurTeam () {
    return (
        <section id="our-team" className="page-section bg-dark text-white">
          <div className="container text-center">
            <h2 className="mb-4">Our team</h2>
            <div className="employees">
              <Employee 
                imgSrc={antonie} 
                linkedinUrl="https://www.linkedin.com/in/antonie-zafirov/"
                mailTo="mailto: antonie.zafirov@95four.com"
                employeeName="Antonie Zafirov"
                employeePosition="Founder & VP Engineering" 
              />
              <Employee 
                imgSrc={filip} 
                linkedinUrl="https://www.linkedin.com/in/filip-trajkovski-841164100/"
                mailTo="mailto: filip.trajkovski@95four.com"
                employeeName="Filip Trajkovski"
                employeePosition="Senior Android Developer" 
              />
            </div>
          </div>
        </section>
    )
}

export default OurTeam;