import React from 'react';

import fontawesome from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import logo from '../assets/img/logo.png';

fontawesome.library.add(faFacebookF, faLinkedinIn, faInstagram, faTwitter)

class Navigation extends React.Component {

  state = {
    scrolled: false
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      if (window.scrollY > 150) {
        this.setState({
          scrolled: true
        })
      } else {
        this.setState({
          scrolled: false
        })
      }
    });
  }

  render() {
    return (
      <div>
        <nav className={"navbar navbar-expand-lg navbar-light fixed-top py-3 " + (this.state.scrolled ? 'navbar-scrolled': '')} id="mainNav">
        <div className="container">
        <a className="navbar-brand js-scroll-trigger" href="#page-top"><img src={logo} alt="home" width="70px" height="70px"/></a>
          <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto my-2 my-lg-0">
              <li className="nav-item">
                <a className="nav-link js-scroll-trigger" href="#about">About Us</a>
              </li>
              <li className="nav-item">
                <a className="nav-link js-scroll-trigger" href="#services">Services</a>
              </li>
              <li className="nav-item">
                <a className="nav-link js-scroll-trigger" href="#portfolio">Portfolio</a>
              </li>
              <li className="nav-item">
                <a className="nav-link js-scroll-trigger" href="#contact">Contact</a>
              </li>
              <li className="nav-item">
                  <a className="nav-link js-scroll-trigger" href="https://www.linkedin.com/company/95four"><FontAwesomeIcon icon={['fab', 'linkedin-in']} /></a>
              </li>
              <li className="nav-item">
                  <a className="nav-link js-scroll-trigger" href="https://www.facebook.com/95four-393615171487015"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>
              </li>
              <li className="nav-item">
                  <a className="nav-link js-scroll-trigger" href="http://instagram.com/95.four"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
              </li>
              <li className="nav-item">
                  <a className="nav-link js-scroll-trigger" href="https://twitter.com/WeAre95Four"><FontAwesomeIcon icon={['fab', 'twitter']} /></a>
              </li>
            </ul>
          </div>
        </div>
      </nav>        
      </div>
    );
  }
}

// function Navigation () {
//     return (
//         <nav className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
//         <div className="container">
//         <a className="navbar-brand js-scroll-trigger" href="#page-top"><img src={logo} alt="home" width="70px" height="70px"/></a>
//           <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
//             <span className="navbar-toggler-icon"></span>
//           </button>
//           <div className="collapse navbar-collapse" id="navbarResponsive">
//             <ul className="navbar-nav ml-auto my-2 my-lg-0">
//               <li className="nav-item">
//                 <a className="nav-link js-scroll-trigger" href="#about">About Us</a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link js-scroll-trigger" href="#services">Services</a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link js-scroll-trigger" href="#portfolio">Portfolio</a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link js-scroll-trigger" href="#contact">Contact</a>
//               </li>
//               <li className="nav-item">
//                   <a className="nav-link js-scroll-trigger" href="https://www.linkedin.com/company/95four"><FontAwesomeIcon icon={['fab', 'linkedin-in']} /></a>
//               </li>
//               <li className="nav-item">
//                   <a className="nav-link js-scroll-trigger" href="https://www.facebook.com/95four-393615171487015"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>
//               </li>
//               <li className="nav-item">
//                   <a className="nav-link js-scroll-trigger" href="http://instagram.com/95.four"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
//               </li>
//               <li className="nav-item">
//                   <a className="nav-link js-scroll-trigger" href="https://twitter.com/WeAre95Four"><FontAwesomeIcon icon={['fab', 'twitter']} /></a>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </nav>
//     )
// }

export default Navigation;