import React from 'react';

function Footer () {
    return (
        <footer className="bg-light py-5">
            <div className="container">
                <div className="small text-center text-muted">Copyright &copy; 2019 - 95four.com</div>
            </div>
        </footer>
    )
}

export default Footer;