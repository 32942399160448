import React from 'react';
import './css/Services.css';
import fontawesome from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faMobile, faUserShield, faServer } from '@fortawesome/free-solid-svg-icons';

fontawesome.library.add(faLaptopCode, faMobile, faUserShield, faServer)

function Services () {
    return (
        <section className="page-section" id="services">
          <div className="container">
            <h2 className="text-center mt-0">THIS IS WHAT WE LOVE TO DO</h2>
            <h6 className="text-center mt-0">…and we are good at it too!</h6>
            <hr className="divider my-4" />
              <div className="row">
                <div className="col-lg-3 col-md-6 text-center">
                  <div className="mt-5">
                    <FontAwesomeIcon icon="server" size="4x" className="text-primary mb-4"/>
                    <h3 className="h4 mb-2">BACKEND DEVELOPMENT</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 text-center">
                  <div className="mt-5">
                    <FontAwesomeIcon icon="laptop-code" size="4x" className="text-primary mb-4"/>
                    <h3 className="h4 mb-2">FRONTEND DEVELOPMENT</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 text-center">
                  <div className="mt-5">
                    <FontAwesomeIcon icon="mobile" size="4x" className="text-primary mb-4"/>
                    <h3 className="h4 mb-2">MOBILE DEVELOPMENT</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 text-center">
                  <div className="mt-5">
                    <FontAwesomeIcon icon="user-shield" size="4x" className="text-primary mb-4"/>
                    <h3 className="h4 mb-2">QUALITY<br />ASSURANCE</h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
    )
}

export default Services;